import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// material
import { Box, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 22 / 14,
  fontSize: 14,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  textAlign: 'center',
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

function MenuDesktopItem({ item, pathname, isHome, isOpen, subCategories, onOpen, onClose }) {
  const { title } = item;
  const path = `/category/${item._id}`;
  const isActive = pathname === path;
  const navigation = useNavigate();
  // if (subCategories) {
  //   return (
  //     <div key={title}>
  //       <LinkStyle
  //         onClick={onOpen}
  //         sx={{
  //           display: 'flex',
  //           cursor: 'pointer',
  //           alignItems: 'center',
  //           ...(isHome && { color: 'common.white' }),
  //           ...{ color: 'text.primary' },
  //           ...(isOpen && { opacity: 0.48 })
  //         }}
  //       >
  //         {title}
  //         <Box
  //           component={Icon}
  //           icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
  //           sx={{ ml: 0.5, width: 16, height: 16 }}
  //         />
  //       </LinkStyle>

  //       <Popover
  //         open={isOpen}
  //         anchorReference="anchorPosition"
  //         anchorPosition={{ top: 80, left: 0 }}
  //         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //         transformOrigin={{ vertical: 'top', horizontal: 'center' }}
  //         onClose={onClose}
  //         PaperProps={{
  //           sx: {
  //             px: 3,
  //             pt: 5,
  //             pb: 3,
  //             right: 16,
  //             margin: 'auto',
  //             maxWidth: 580,
  //             borderRadius: 2,
  //             boxShadow: (theme) => theme.customShadows.z24
  //           }
  //         }}
  //       >
  //         <List disablePadding>
  //           <ListSubheader
  //             disableSticky
  //             disableGutters
  //             sx={{
  //               display: 'flex',
  //               lineHeight: 'unset',
  //               alignItems: 'center',
  //               color: 'text.primary',
  //               typography: 'overline'
  //             }}
  //           >
  //             <IconBullet type="subheader" /> {title}
  //           </ListSubheader>

  //           {subCategories.map((subItem) => (
  //             <ListItem
  //               key={subItem.title}
  //               to={`/category/${subItem._id}`}
  //               component={RouterLink}
  //               underline="none"
  //               sx={{
  //                 p: 0,
  //                 mt: 3,
  //                 typography: 'body2',
  //                 color: 'text.secondary',
  //                 transition: (theme) => theme.transitions.create('color'),
  //                 '&:hover': { color: 'text.primary' },
  //                 ...(subItem.path === pathname && {
  //                   typography: 'subtitle2',
  //                   color: 'text.primary'
  //                 })
  //               }}
  //             >
  //               <>
  //                 <IconBullet />
  //                 {subItem.title}
  //               </>
  //             </ListItem>
  //           ))}
  //         </List>
  //       </Popover>
  //     </div>
  //   );
  // }

  if (title === 'Documentation') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        sx={{
          ...(isHome && { color: 'common.white' }),
          color: 'text.primary',
          ...(isActive && { color: 'primary.main' })
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={Link}
      sx={{
        ...(isHome && { color: 'common.white' }),
        color: 'text.primary',
        ...(isActive && { color: 'primary.main' })
      }}
    >
      {title}
    </LinkStyle>
  );
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  //   const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (open) {
  //     handleClose();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link._id}
          item={link}
          subCategories={link.subcategories}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
}
