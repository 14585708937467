import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { AppBar, Box, Button, Container, Stack, Toolbar } from '@material-ui/core';

import { styled } from '@material-ui/core/styles';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../redux/slices/product';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components

import { MHidden } from '../../components/@material-extend';

//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const categories = useSelector((state) => state.product.categories);
  const navigation = useNavigate();

  const handleAuth = () => {
    navigation('/auth/login');
  };

  //   categoryItem
  //   {
  //     "_id": "6658d8945cf3b4990e50c9b2",
  //     "store_id": "665256abf4203eef4ab7f078",
  //     "title": "Spor ve Outdoor",
  //     "status": true,
  //     "category_id": "6658d8945cf3b4990e50c9b2",
  //     "lastModified": "2024-05-30T19:51:35.942Z",
  //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjY0Y2RjZThjYzYzNjA3Njg0M2U0ZjU2IiwiZW1haWwiOiJtaWFAdGljYXJldC5jb20iLCJpYXQiOjE3MTcwOTg2MTQsImV4cCI6MTcxNzcwMzQxNH0.WWsq3KxkAUfzBuzrU57P3No9T1OIBWcOflnr59Zd52Y"
  // }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'white' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'white',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <img
              src="/static/logo.png"
              alt="app logo"
              style={{
                maxHeight: '40px'
              }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1, width: '50px' }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={categories} />
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={categories} />
          </MHidden>

          {/* <Button variant="contained">Giriş Yap</Button> */}
          <Stack direction="row" justifyContent="flex-end">
            <Button
              fullWidth
              type="button"
              color="primary"
              variant="outlined"
              size="large"
              sx={{ mr: 1.5 }}
              onClick={handleAuth}
            >
              Kayıt
            </Button>
            <Button
              fullWidth
              type="button"
              color="primary"
              variant="outlined"
              size="large"
              sx={{ mr: 1.5 }}
              onClick={handleAuth}
            >
              Giriş
            </Button>
          </Stack>
          {/* <Button variant="contained">Kayıt Ol</Button> */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
